import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import { Logo, navimg } from "../../assets";
import {
  FaTachometerAlt,
  FaGem,
  FaList,
  FaSpellCheck,
  FaRegLaughWink,
  FaHeart,
  FaRegSun,
  FaRegUserCircle,
  FaHeadphonesAlt,
} from "react-icons/fa";
import { ImCross } from "react-icons/im";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
const Aside = ({ collapsed, toggled, handleToggleSidebar, handleToggle }) => {
  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          <img src={Logo} alt="logo"></img>
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="FaQuestionCircle">
          <Link onClick={() => handleToggle()} to={"/about-us"}>
            <MenuItem
              icon={<FaSpellCheck />}
              suffix={<span className="badge red">{"new"}</span>}
            >
              {"About Us"}
            </MenuItem>
          </Link>
          <Link onClick={() => handleToggle()} to={"/support"}>
            <MenuItem icon={<FaRegUserCircle />}> {"Support"}</MenuItem>
          </Link>
        </Menu>
        <Menu iconShape="">
          <SubMenu
            suffix={<span className="badge yellow"></span>}
            title={"Services"}
            icon={<FaRegSun />}
          >
            <Link onClick={() => handleToggle()} to={"/hosted-pbx"}>
              <MenuItem>{" Hosted PBX"} </MenuItem>
            </Link>
            <Link onClick={() => handleToggle()} to={"/call-center-solutions"}>
              <MenuItem>{"Call Center Solutions"}</MenuItem>
            </Link>
            <Link onClick={() => handleToggle()} to={"/voip-telephony"}>
              <MenuItem>{"VoIP Telephony"} </MenuItem>
            </Link>
            <Link onClick={() => handleToggle()} to={"/toll-free-number"}>
              <MenuItem>{"Toll Free Number"} </MenuItem>
            </Link>
            <Link onClick={() => handleToggle()} to={"/sip-trunking"}>
              <MenuItem>{"SIP Trunking"} </MenuItem>
            </Link>
          </SubMenu>

          <SubMenu title={"Features"} icon={<FaList />}>
            <SubMenu title={`${"Calling Features"}`}>
              <Link onClick={() => handleToggle()} to={"/call-recording"}>
                <MenuItem>{""} Call Recordings </MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/call-forward"}>
                <MenuItem>{""} Call Forwarding </MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/call-balsting"}>
                <MenuItem> Call Blast</MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/call-waiting"}>
                <MenuItem> Call Waiting</MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/call-conferencing"}>
                <MenuItem> Call Conferencing</MenuItem>
              </Link>

              <Link onClick={() => handleToggle()} to={"/call-Screen-announce"}>
                <MenuItem> Call Screen and Announce</MenuItem>
              </Link>
            </SubMenu>
            <SubMenu title={`${"Call Management "}`}>
              <Link onClick={() => handleToggle()} to={"/acd"}>
                <MenuItem>{""} ACD PBX </MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/dnd"}>
                <MenuItem>{""} Do Not Disturb </MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/music-on-hold"}>
                <MenuItem>{""} Music On Hold </MenuItem>
              </Link>

              <Link onClick={() => handleToggle()} to={"/dail-by-name"}>
                <MenuItem>Dial By Name</MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/customized-messages"}>
                <MenuItem>Customized Messages</MenuItem>
              </Link>
              <Link
                onClick={() => handleToggle()}
                to={"/call-queue-management"}
              >
                <MenuItem>Call Queue Management</MenuItem>
              </Link>
            </SubMenu>
            <SubMenu title={`${" Miscellenous  "}`}>
              <Link onClick={() => handleToggle()} to={"/voice-mail"}>
                <MenuItem>{""} Voicemail </MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/internet-fax"}>
                <MenuItem>{""} Internet Fax </MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/hosted-ivr"}>
                <MenuItem>{""} Hosted IVR </MenuItem>
              </Link>
              <Link onClick={() => handleToggle()} to={"/softphone"}>
                <MenuItem>{""} Softphone </MenuItem>
              </Link>
            </SubMenu>
          </SubMenu>
        </Menu>
      </SidebarContent>

      <SidebarFooter>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <Link to="/" className="sidebar-btn" rel="noopener noreferrer">
            <span
              style={{
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
              }}
            >
              <Link to={`/contact-us`}>
                {" "}
                <Button Button variant="contained" color="secondary">
                  <FaHeadphonesAlt className="svgwhite" /> &nbsp; Get Support
                </Button>
              </Link>
            </span>
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;
