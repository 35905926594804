import React from "react";
import { Outbond_Call, Inbond_Call,tfn4,Pict } from "../../assets";
import Helmet from 'react-helmet';
const CallRecording = () => {
  return (
    <div>
          <Helmet>
                <meta charSet="utf-8" />
                <title>Call recording: Improve your employee productivity with call
recording feature.</title>
                <meta name="description" content="Call recording: Improve your employee productivity with call
recording feature." />
            </Helmet>
    <div className="containe text-center bg-themebg pt-5">
      <div className="container">
        <div className="section-padding">
          <h1 class="home-text py-2">
          Call<span class="span-color"> Recording </span>
          </h1>
          <p class="hone-p">
          Boost your business productivity and improve your employee's performance with voxofy's advanced call recording features
          </p>
        </div>
      </div>
    </div>

    <div className="container text-center">
        <div className="section-padding">
          <p>
            Call recording is the term which we use day in and day out which in
            simple words it means recording of the voice while the call is
            ongoing, however it has a huge impact on today's business as they
            solve many concerns and call recording can be brought in use while
            solving the complaint of the customer, in order to ensure policy and
            compliance is being followed on calls, for training purposes and so
            forth Voxofy Networks LLC indeed understands the significance of
            this trait for the organization and as a result we offer best
            quality call recording, in order to keep you abreast of technology
            we offer you call recording with the advanced features.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={tfn4}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
               Features of Call Recording
              </div>
              <p className="f-18">
                Nodes –
                There are numerous options available to pick the nodes between
                which the calls can be recorded and they are listed as below
              </p>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Extension to Extension calls.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; External Incoming Calls.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; External Outgoing calls.
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>{" "}
                    &nbsp; Conference Calls.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <p>
            Storage Location –will decide the location where the call be stored.
            Voxofy Networks LLC provides cloud storage for all your call
            recordings. Call recordings are securely managed and can be
            available to the user permitted by you. The user can use the
            features like download, listen and the delete the call recording You
            can also amalgamate any other cloud base platform to save the call
            recordings, for the period of 30 days' call recording will be there
            to download, delete and so forth however in case of business
            requirement the engineers can do changes in the default settings
            Voxofy Networks LLC completely understand the value of call recoding
            in your business hence we ensure that the call recordings are
            completely stored with privacy and security, as we have hand on
            experience in the cloud industry, we have professionals working for
            us who deliver secure cloud computing As we all know that the laws
            vary from region to region hence we ask upfront to our clients to
            follow the laws for their operations
          </p>
          <br/>
        </div>
      </section>
  </div>
  );
};
export default CallRecording;
