import React from "react";
import { tfn3, tfn5 } from "../../assets";
import { Helmet } from "react-helmet";
const CallScreenAnnounce = () => {
  return (
    <div>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Call screen and announce: Deliver the right information to right
person with call screen and Announce</title>
                <meta name="description" content="Call screen and announce: Deliver the right information to right
person with call screen and Announce." />
            </Helmet>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Call Screen<span class="span-color"> Announce</span>
            </h1>
            <p class="hone-p">
            Voxofy Networks LLC Call Screening Announce VOIP allows you to prioritize the call by deciding which call to answer and which to block
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p>
            Voxofy Networks LLC has an advanced feature which is Call Screen and
            Announce which lets the representative to start the conversation by
            giving the professional touch as they will be able to hear the
            Announcement which will come across as You have a call for support
            from the name of the person, to answer the same press1, to send the
            same to voicemail press 2, to route the call to another extension
            press 3, to keep the call on hold press 4 Now with this feature, the
            representative can answer the call well prepared and will also be
            able to overcome the concern of misreading the name as the caller at
            times might get offended because of the same concern, but now with
            Call Screen and Announce feature representative will be able to hear
            the name of the caller in his or her voice and at the same time one
            will be able to decide how the call should be tackled while the
            caller on hold will be able to a customized music of their choice
            and you can also this opportunity to make the caller aware about the
            latest promotion or offering which might fascinate the caller.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={tfn5}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Voxofy Call Screening Announce
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Take Charge </span> of your Business
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  Voxofy Networks LLC Call Screening Announce VOIP allows you to
                  prioritize the call by deciding which call to answer and which
                  to block, and it also helps you to handle the call more
                  efficiently, with the trait of Call Screening and Call
                  Announce you will be provided with most of the information so
                  that you can address the concern aptly at the right point in
                  time, if that is not the time to speak you can choose from the
                  numerous options how to answer the call as Call Park, Call
                  Hunt, Virtual Office, Automated Operator, Voicemail, ACID,
                  DID, IVR and so forth.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Voxofy Call Screening Announce
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Call Screening Announce </span> VOIP
                Increases Productivity
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  The trait of Call Screening and Call Announce allows the
                  representative to see he caller name, number as it will be
                  displayed automatically so that the representative can make
                  some changes You can give priority to some business call over
                  others You can avoid promotional calls and direct them to go
                  on the voicemail Block the numbers who are trying to be
                  pranked. Differentiate between the ongoing call and the fresh
                  call. It will indeed increase the productivity as the employee
                  can make the instant decision which call to answer and the
                  other can be routed to the voicemail, these also helps us to
                  overcome the advertising calls hence you will be able to save
                  time and will be able to make most out of the answered calls
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={tfn3}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CallScreenAnnounce;
