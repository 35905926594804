import React from "react";
import { Outbond_Call, cc2 } from "../../assets";
import { Helmet } from "react-helmet";
const Hosted = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Hosted PBX: Flexible Hosted PBX service for your business
        </title>
        <meta
          name="description"
          content="Hosted IVR: Ensure non-stop customer service with Hosted
IVR"
        />
      </Helmet>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Hosted <span class="span-color">PBX</span>
            </h1>
            <p class="hone-p">
              One can experience the complete new, flexible Hosted PBX set up
              you may be familiar by the name of Cloud PBX
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <h1 className="home-text py-2">
            Change your Business Outlook with{" "}
            <span class="span-color"> Cloud Hosted PBX </span>
          </h1>
          <p>
            The Voxofy Networks LLC hosted PBX service is one stop solution for
            all communication requirements and for the same one will not be
            required to pay anything for the installation, maintenance and at
            the same time one can also overcome the problems of typical hardware
            phone.{" "}
          </p>
          <br />
          <p>
            Voxofy Networks LLC hosted PBX service is one-stop solution for all
            communication requirements as it eliminates the problems of a
            typical hardware phone and one is not required to pay anything for
            the installation and maintenance. The cloud set up brings in the
            picture multiple channels which will indeed bring out better
            quality, improve productivity and as as a result will boost the
            entire communication network. Our key features are that it is user
            friendly, cost effective and just to bring to your notice our Hosted
            PBX services are bespoke to meet the needs of a client which means
            that one can add or remove the extension on the daily and hourly
            basis, being in touch with the globe by taking care of the incoming
            and outgoing calls. We have plug and play cloud communication and it
            takes nano seconds with the required resources whenever the need be
            to access .
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={cc2} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Voxofy Networks LLC Hosted PBX
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr"> Establish a strong </span>{" "}
                communication network with plug and play cloud set up
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  We have plug and play cloud communication and it takes nano
                  seconds with the required resources whenever the need be to
                  access ..
                </p>
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>

      {/* <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
              Voxofy Networks LLC Hosted PBX
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Hosted PBX </span> set up you may be
                familiar by the name of Cloud PBX
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  We have plug and play cloud communication and it takes nano
                  seconds with the required resources whenever the need be to
                  access .
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={Outbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
export default Hosted;
