import React from "react";
import { Outbond_Call, tfn1 } from "../../assets";
import { Helmet } from "react-helmet";
const Donotdisturb = () => {
  return (
    <div>
        <Helmet>
                <meta charSet="utf-8" />
                <title>DND: Get rid of unwanted calls with DND feature</title>
                <meta name="description" content="DND: Get rid of unwanted calls with DND feature" />
            </Helmet>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Do Not <span class="span-color"> Disturb </span>
            </h1>
            <p class="hone-p">
            Do not Disturb is a feature that gives the freedom of disabling the incoming call on the extension .
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p className="f-18">
            Phone calls have made communication much easier and in deed made
            world a better place to live in as we all know that everything has
            its own pros and cons similarly phone calls at times become
            hindrance in productivity and hence one feels to stay away however
            at times there is no get away. Do not Disturb is a feature available
            with Do not Disturb of Voxofy Networks LLC which will give the
            freedom of disabling the incoming call on the extension, when it is
            enable no call will get through on the extension until and unless it
            is not removed and at the same time the call can be routed to other
            extension or can get through the voicemail and helps the individual
            to lay down the emphasis on the work they are doing There can be
            situation at your work or personal front when you just don't want or
            can't entertain the phone calls as while you are in meeting, just
            missing the deadline, away with family which means that phone calls
            are hindrance at this point in time rather than any advantage. This
            trait also comes handy when the representative is away from the desk
            or leaves for the day, it will ensure that the call should be missed
            and they are utilized effectively.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={tfn1}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Voxofy Networks LLC DND
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">How to employ </span> Do Not Disturb
                on Do not Disturb
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  One will be required to active the feature on the smartphone,
                  as soon as it is active it will block all the incoming calls
                  to come through however in settings one can choose and
                  customize the options which they want to active one can also
                  set the voicemail or route the call to another extension which
                  is a very handy feature and will ensure that the customer
                  concern is dealt as per their convenience
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};
export default Donotdisturb;
