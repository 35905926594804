import React from "react";
import ReactDOM from "react-dom";
import "./Navbar.css";
import navbarItems from "./NavbarItems";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { Logo, navimg } from "../../assets";
import { Button } from "@material-ui/core";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";

const NavbarB = ({ isMobile, handleToggleSidebar }) => {
  const desktopmenu = () => {
    return (
      <>
      <div className="container">
      <div className="menu-items">
        <Nav  className="me-auto my-2 my-lg-0 pl-215" navbarScroll>
          <NavDropdown
            title="Services"
            id="navbarScrollingDropdown"
            className="pl-20"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-4 p-20">
                  <Link to={`/hosted-pbx`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/hosted-pbx`}
                      className="p-20"
                    >
                      <h4>Hosted PBX</h4>
                      <p> The Voxofy hosted PBX service.</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/call-center-solutions`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/call-center-solutions`}
                      className="p-20"
                    >
                      <h4>Call Center Solutions</h4>
                      <p>Call center solution is primary..</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/voip-telephony`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/voip-telephony`}
                      className="p-20"
                    >
                      <h4>VoIP Telephony</h4>
                      <p>Empower your business setup with..</p>
                    </NavDropdown.Item>
                  </Link>
                </div>

                <div className="col-md-4 p-20">
                  <Link to={`/toll-free-number`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/toll-free-number`}
                      className="p-20"
                    >
                      <h4>Toll Free Number</h4>
                      <p>A brilliant mechanism which ..</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/sip-trunking`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/sip-trunking`}
                      className="p-20"
                    >
                      <h4>SIP Trunking</h4>
                      <p> SIP Trunking along with well-built </p>
                    </NavDropdown.Item>
                  </Link>
                </div>

                <div className="col-md-4 p-20 text-center them-bg">
                  <div className="p-40">
                    <img src={navimg} alt="alt" style={{ Width: "100%" }}></img>

                    <p>Our Special Features</p>
                    <h2 className="voipfont">VOIP SUPPORT</h2>
                    <Link to={`/voip-telephony`}>
                      {" "}
                      <button type="button" class="btn btn-warning">
                        Learn More {<ArrowRightAltIcon />}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </NavDropdown>

          <NavDropdown
            title="Features"
            id="navbarScrollingDropdown"
            className="pl-20"
          >
            <div className="container">
              <div className="row">
                <div className="col-md-4  p-20">
                  <b className="pl-3">Calling Features</b>
                  <NavDropdown.Divider />
                  <Link to={`/call-recording`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/call-recording`}
                      className="p-20"
                    >
                      <h4> Call Recordings </h4>
                      <p>Call recording is the term which..</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/call-forward`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/call-forward`}
                      className="p-20"
                    >
                      <h4>Call Forwarding</h4>
                      <p>Call Forwarding is the prominent</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/call-balsting`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/call-balsting`}
                      className="p-20"
                    >
                      <h4>Call Blast</h4>
                      <p>Call blast is the most impactful..</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/call-waiting`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/call-waiting`}
                      className="p-20"
                    >
                      <h4> Call Waiting </h4>
                      <p>During the specific time zone</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/call-conferencing`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/call-conferencing`}
                      className="p-20"
                    >
                      <h4> Call Conferencing </h4>
                      <p>Call Conferencing is an amazing gadget</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/call-Screen-announce`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/call-Screen-announce`}
                      className="p-20"
                    >
                      <h4> Call Screen and Announce </h4>
                      <p>Call Screen and Announce which..</p>
                    </NavDropdown.Item>
                  </Link>
                </div>

                <div className="col-md-4 p-20">
                  <b className="pl-3">Call Management </b>
                  <NavDropdown.Divider />
                  <Link to={`/acd`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/acd`}
                      className="p-20"
                    >
                      <h4>ACD PBX</h4>
                      <p>In today's time it is a struggle to</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/dnd`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/dnd`}
                      className="p-20"
                    >
                      <h4>Do Not Disturb</h4>
                      <p>Phone calls have made communication</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/music-on-hold`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/music-on-hold`}
                      className="p-20"
                    >
                      <h4>Music On Hold</h4>
                      <p>Keeping the customer on hold is not</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/dail-by-name`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/dail-by-name`}
                      className="p-20"
                    >
                      <h4>Dial By Name</h4>
                      <p>Virtual PBX number system make it..</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/customized-messages`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/customized-messages`}
                      className="p-20"
                    >
                      <h4>Customized Messages</h4>
                      <p>We firmly believe in delivering the </p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/call-queue-management`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/call-queue-management`}
                      className="p-20"
                    >
                      <h4>Call Queue Management</h4>
                      <p>The old PBX was not that advanced it</p>
                    </NavDropdown.Item>
                  </Link>
                </div>

                <div className="col-md-4 p-20">
                  <b className="pl-3"> Miscellenous </b>
                  <NavDropdown.Divider />
                  <Link to={`/voice-mail`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/voice-mail`}
                      className="p-20"
                    >
                      <h4> Voicemail </h4>
                      <p>Business needs instant communication</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                  <Link to={`/internet-fax`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/internet-fax`}
                      className="p-20"
                    >
                      <h4>Internet Fax </h4>
                      <p>Even in this golden era of ..</p>
                    </NavDropdown.Item>
                  </Link>

                  <NavDropdown.Divider />

                  <Link to={`/hosted-ivr`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/hosted-ivr`}
                      className="p-20"
                    >
                      <h4> Hosted IVR </h4>
                      <p>Focus on Phenomenal Customer ..</p>
                    </NavDropdown.Item>
                  </Link>
                  {/* <NavDropdown.Divider />

                  <NavDropdown.Item href="#" className="p-20">
                    <h4> Unified Communications </h4>
                    <p>Lorem Ipsum is simply dummy text</p>
                  </NavDropdown.Item> */}
                  <NavDropdown.Divider />
                  <Link to={`/softphone`}>
                    <NavDropdown.Item
                      href={`${process.env.PUBLIC_URL}/softphone`}
                      className="p-20"
                    >
                      <h4> Softphone </h4>
                      <p>Through Smartphone programming</p>
                    </NavDropdown.Item>
                  </Link>
                  <NavDropdown.Divider />
                </div>
              </div>
            </div>
          </NavDropdown>

          {/* <Nav.Link href="/" className="pl-20 ml-3">Pricing</Nav.Link> */}

          <Link to={`/support`}>
            {" "}
            <Nav.Link
              href={`${process.env.PUBLIC_URL}/support`}
              className="pl-20 ml-3"
            >
              Support
            </Nav.Link>
          </Link>

          <Link to={`/about-us`}>
            <Nav.Link
              href={`${process.env.PUBLIC_URL}/about-us`}
              className="pl-20 ml-3"
            >
              About Us
            </Nav.Link>
          </Link>

        </Nav>
        </div>
        <Nav className="d-flex">
          <div className="icons">
            {/* <Link to="/contact-us" className="link">
              <Button Button variant="contained" color="secondary">
                Contact Us
              </Button>
            </Link> */}
          </div>
        </Nav>
      </div>
      </>
    );
  };
  return (
    <nav className="Desktop_view">
      <Link to="/" className="link">
        <img src={Logo} alt="logo"></img>
      </Link>
      {!isMobile ? (
        desktopmenu()
      ) : (
        <div className="icons">
          <div className="mobile-menu-icon"  onClick={() => handleToggleSidebar(true)}>
            <FaBars />
          </div>
        </div>
      )}

      <div className="icons" id="mobile-none">
        <Link to={`/contact-us`} className="link">
          <Button Button variant="contained" color="secondary" id="contact-nav">
            Contact Us
          </Button>
        </Link>

      </div>
    </nav>
  );
};

export default NavbarB;
