import React from "react";
import { Outbond_Call, tfn2, Pict } from "../../assets";

const Support = () => {
  return (
    <div>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              <span class="span-color"> Support </span>
            </h1>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p className="f-18">
            Support cat as a cushion for the business which ensures that even in
            uncertain circumstances the business will keep on going. We live in
            century where time is money so will ensure that there is no delay in
            providing the support when needed in regards to sales, installation,
            maintenance, customization and so forth. You can reach our team of
            professionals through the mode you are accustomed with:
          </p>
          <br />
        </div>
      </div>

      <section className="mt-5 pb-4 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={tfn2} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div className="pt-4 mt-5 py-4 bg-lightto">
                <p className="f-18 text-white text-center py-2 f-21">
                  <span className="f-21"> Chat - </span><a className="f-21" href="mailto:support@voxofy.com"> Click Here </a>
                </p>
              </div>
              <div className="pt-4 py-4 mt-5 bg-lightto">
                <p className="f-18 text-white text-center py-2 f-21">
                  <span className="f-21"> Support - </span><a className="f-21" href="mailto:support@voxofy.com"> support@Voxofy.com </a>
                  {/* <span className="f-21"> Support - </span><a className="f-21" href="tel:+1 (888) 480-9777 "> support@Voxofy.com </a> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="container text-center">
        <div className="section-padding">
          <p className="f-18">
            We lay our emphasis on making sure that our technology is
            user-friendly and we firmly believe in maintain the long term
            relations, there are number of things for which one looks the
            assistance for as installing of security updates from time to time,
            adjustment to new hardware, synchronization with operating system
            update and so forth however what may be there should not a single
            second loss for the business and one can simply reach to our support
            team and experts will ensure that the concern is taken care aptly
            and without consuming too much time.
          </p>
          <br />
        </div>
      </div>
    </div>
  );
};
export default Support;
