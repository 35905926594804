import React from "react";
import { whitelogo } from "../../assets";
import Icon from "@material-ui/core/Icon";
import SendIcon from "@material-ui/icons/Send";
import { Link } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

function ScrollTop(props) {
  const { children, window } = props;
  const classes = useStyles();
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).getElementById(
      "topnavbar"
    );
    console.log(anchor);
    if (anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  return (
    <Zoom in={trigger} className="d-none">
      <div
        onClick={handleClick}
        role="presentation"
        className="d-none"
        className={classes.root}
      >
        {children}
      </div>
    </Zoom>
  );
}
const AppFooter = (props) => {
  const classes = useStyles();
  return (
    <div className="p-12">
      <div className="bg-themecolor">
        <div className="container text-center section-padding">
          <div className="row">
            <h1 className="text_40">
              Getting started takes less than 5 minutes
            </h1>
            <p className="font-20 ">
              Get a new phone number or port your existing numbers, invite your
              team members and start making & receiving calls.
            </p>
            <div className="text-center">
              <Link to="/contact-us">
                {" "}
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                >
                  Start Now
                </Button>{" "}
              </Link>
            </div>

            {/* <p className="mt-3 f-17">
              Virtual PBX number system make it very easy for the employees to
              get connected to each other
            </p> */}
          </div>
        </div>
      </div>
      <div className="footer-bacground section-padding">
        <div className="container">
          <div className="row">
            <div className="text-center">
              <img src={whitelogo} className="whitelogo" alt="white logo" />
              <p className="footer-text color-white">
                Voxofy is one platform where you get appropriate and
                cost-effective cloud-based communication solutions for your
                contact centre. We provide custom-built communication services
                within stipulated time and ensure smooth communication process
                for your organisation.
              </p>
            </div>
            <br />
            <hr />
            <br />
          </div>
          <div className="row">
            <div className="col-md-3 col-6">
              <h2>Get In Touch</h2>
              <ul className="service">
                <p className="py-3 text-white">
                  Voxofy Networks LLC is prominent for providing sterling cloud
                  based services to all emerging businesses. We provide one stop
                  solutions for your growing business needs.
                </p>
                <li> +1 (888) 480-9777,</li>
                <li> support@voxofy.com</li>
              </ul>
            </div>
            <div className="col-md-3 col-6">
              <h2>Our Services</h2>
              <ul className="service">
                <li>
                  <Link to="/hosted-pbx">Hosted PBX </Link>
                </li>
                <li>
                  <Link to="/call-center-solutions">Call Center Solutions</Link>
                </li>
                <li>
                  <Link to="/voip-telephony">VoIP Telephony</Link>
                </li>
                <li>
                  <Link to="/toll-free-number">Toll Free Number</Link>
                </li>
                <li>
                  <Link to="/sip-trunking">SIP Trunking </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-6">
              <h2>Popular</h2>
              <ul className="service">
                <li>
                  <Link to="/call-center-solutions">Call Center Solutions</Link>
                </li>
                <li>
                  <Link to="/hosted-pbx">Hosted PBX </Link>
                </li>

                <li>
                  <Link to="/voip-telephony">VoIP Telephony</Link>
                </li>
                <li>
                  <Link to="/toll-free-number">Toll Free Number</Link>
                </li>
                <li>
                  <Link to="/sip-trunking">SIP Trunking </Link>
                </li>
              </ul>
            </div>
            <div className="col-md-3 col-6">
              <h2>Company</h2>
              <ul className="service">
                <li>
                  <Link to="/about-us">About Us </Link>
                </li>
                <li>
                  <Link to="/dail-by-name">Dial By Name</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-and-conditions">Terms & Conditions </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="continer text-center">
        <div className="py-3 bg-dark">
          <p className="text-white">
            Voxofy Networks LLC © Copyright 2021 . All Rights Reserved.{" "}
          </p>
        </div>
      </div>

      <ScrollTop {...props}>
        <Fab color="secondary" size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </div>
  );
};
export default AppFooter;
