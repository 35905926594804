import React from "react";
import { Outbond_Call, cc, Pict } from "../../assets";
import { Helmet } from "react-helmet";
const Voicemail = () => {
  return (
    <div>
          <Helmet>
        <meta charSet="utf-8" />
        <title>
        Voicemail: Get an advance Voicemail system for seamless
customer service.
        </title>
        <meta
          name="description"
          content="Voicemail: Get an advance Voicemail system for seamless
customer service."
        />
      </Helmet>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Voice <span class="span-color"> Mail </span>
            </h1>
            {/* <p class="hone-p">
              One can experience the complete new, flexible Hosted PBX set up
              you may be familiar by the name of Cloud PBX
            </p> */}
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p className="f-18">
            Business needs instant communication as in today's time every minute
            counts, there are experts who value instant response in this case
            Telephony will always prove to be fruitful. As time changes hence
            the mode for communication has also changed as fax, emails do exist
            however it does not give the immediate response. If one wants to
            reach the height of success you will have to ensure that personal
            touch is maintained. Communication in the recent time has come
            across so many changes and also ensure that one can speak to masses
            at one point in time, you can relate to this through electronic mail
            Yes, it is completely true that communication is possible through
            different ways, however in order to give personal touch one cannot
            overlook high definition quality which by spea king to the live
            representative one gets.
          </p>
          <h1 className="home-text mt-5 mb-3"> Need for Voicemail </h1>
          <p className="f-18">
            Everyone pays attention to utilize their time efficiently, there can
            be instances that an individual can miss on taking the call or the
            call gets unanswered, this is where one feels the need for voicemail
            as the messages gets stored and the use is then able to make sure
            which call was important and they can access the messages at their
            own convenience. One can listen to messages at any time, any place
            as they are linked to any device or system and how this is possible,
            one will be required to dial in their central number and the code to
            get access to the account
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={cc}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Voxofy Networks Voicemail PBX
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr"> To understand </span>
                the Voicemail, consider the following points
              </h1>
              <div className="mt-4">
                <ul className="p-0">
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>
                    &nbsp; Voicemail is no different than the answering machine
                    however it come with the latest feature which is flexibility
                    to use
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>
                    &nbsp;One can access the same even being not present in the
                    office premises
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>
                    &nbsp; One can also use the same to send and receive
                    messages to others mail box as well
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>
                    &nbsp; User cans store the messages as long as they want
                  </li>
                  <li>
                    <img src={Pict} alt="check" className="width-20 pl-2"></img>
                    You can take multiple calls at the same time
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <p className="mt-5 pb-5 f-18">
            All the features are accumulated to ensure that one gets all the
            messages well in time. Our main emphasis is on providing the
            services which is easy to access and that is only possible as it
            will have all the new technology. We provide services to all type of
            business
          </p>
        </div>
      </section>


    </div>
  );
};
export default Voicemail;
