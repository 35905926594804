import React from "react";
import { Outbond_Call, cc3 } from "../../assets";
import { Helmet } from "react-helmet";
const Softphone = () => {
  return (
    <div>
              <Helmet>
        <meta charSet="utf-8" />
        <title>
        Softphone: Reduce your telephony cost with softphone
programming.
        </title>
        <meta
          name="description"
          content="Softphone: Reduce your telephony cost with softphone
programming."
        />
      </Helmet>
      <div className="containe text-center bg-themebg">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Softphone <span class="span-color"> </span>
            </h1>
            <p class="hone-p">
            Switch to softphones and stay connected with your callers all day from anywhere.
            </p>
          </div>
        </div>
      </div>

      <section className="pt-3 section-padding sec3color">
        <div className="container mt-5">
          <div className="row mt-5">
            <div className="col-lg-6  ">
              <img
                src={cc3}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Voxofy Networks LLC Softphone
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Through Smartphone </span> programming
                you
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  Through Smartphone programming you can make and receive calls
                  on the device without having any specified phone hardware.
                  Smartphone is available versions and UI one can chose as per
                  their requirement, mix of traits, style and design. As we are
                  well aware about the impeccable features of Voxofy Networks
                  LLC however it still gives the upper hand to the client in
                  order to customize and our services go along with all leading
                  smartphones Smartphone plays a major role in order to ensure
                  that the quality experience as it is a medium of conversation
                  in other words exchange of messages between the caller and the
                  VIOP network In order to get the firsthand experience, you can
                  avail the free trail and then opt for the services.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="section-padding">
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
                Voxofy Networks LLC Hosted PBX
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Hosted PBX </span> set up you may be
                familiar by the name of Cloud PBX
              </h1>
              <div className="mt-4">
                <p className="f-18">
                  We have plug and play cloud communication and it takes nano
                  seconds with the required resources whenever the need be to
                  access .
                </p>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={Outbond_Call}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};
export default Softphone;
