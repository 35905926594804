import React from "react";
import { Outbond_Call, Inbond_Call } from "../../assets";
import { Helmet } from "react-helmet";
const Hostedivr = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Hosted IVR: Ensure non-stop customer service with Hosted IVR
        </title>
        <meta
          name="description"
          content="Hosted IVR: Ensure non-stop customer service with Hosted
IVR"
        />
      </Helmet>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Hosted <span class="span-color"> IVR </span>
            </h1>
            {/* <p class="hone-p">
              One can experience the complete new, flexible Hosted PBX set up
              you may be familiar by the name of Cloud PBX
            </p> */}
          </div>
        </div>
      </div>

      <div className="container">
        <div className="section-padding">
          <h1 className="home-text py-2">
            Focus on Phenomenal Customer Service experience with
            <span class="span-color"> Hosted IVR </span>
          </h1>
          <p>
            <strong>IVR – </strong> interactive voice response is one of the
            modish technology in today's time which is available with Voxofy
            Networks LLC, as we are moving towards the western culture slowly
            and gradually we are learning the concept of self service, similarly
            IVR system helps the organization by helping the caller to select
            from the options and if need be reaching the right department on
            their own IVR system helps the caller to get hand on the specific
            information as extension numbers, branch address, update information
            and recent changes and so forth which will be for sure useful for
            the customer
          </p>
          <br />
          <h1 className="home-text py-2">
            Advantages of
            <span class="span-color"> Hosted IVR </span>
          </h1>
          <p className="py-2">
            <strong> Security- </strong> it Is the most secure system as at some
            instance there is some information which cannot be shared even with
            the representative in other words it is really confidential as
            Telephonic Identity PIN
          </p>
          <p className="py-2">
            <strong>Helps in reduction of Manpower- </strong> the IVR system
            works without the human involvement and the system is capable of
            handling some concern
          </p>

          <p className="py-2">
            <strong>Call Recordings – </strong> with the advanced technology we
            do not leave any stone unturned which might be helpful in the future
            , so we also get the IVR Calls recorded on the cloud . the recording
            can be accessed by the authorized purpose can be used for different
            purpose
          </p>

          <p className="py-2">
            {" "}
            <strong> Cost Effective – </strong> as we have already mentioned
            that the IVR itself can take care of some concern so the
            organization will not be required to add any representative to
            answer small query · Real Time – the cloud hosted IVR adds on as the
            authorized user can get the real time data about the performance and
            productivity · Support – we provide support 24 by 7 across the globe
            at no additional cost and we are also well planned with plans for
            the downtime so that the client does not have to bear the losses as
            we firmly believe that Money Saved is Money Earned
          </p>
        </div>
      </div>
    </div>
  );
};
export default Hostedivr;
