import React from "react";
import { Outbond_Call, Inbond_Call , Pict ,tfn2,tfn3} from "../../assets";
import { Helmet } from "react-helmet";
const SIPtrunk = () => {
  return (
    <div>
           <Helmet>
        <meta charSet="utf-8" />
        <title>
        SIP Trunking: Integrate voice and data with advance traits of
SIP trunking
        </title>
        <meta
          name="description"
          content="SIP Trunking: Integrate voice and data with advance traits of
SIP trunking."
        />
      </Helmet>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              SIP <span class="span-color">Trunking</span>
            </h1>
            <p class="hone-p">
            Voxofy Networks LLC brings all the traits of SIP Trunking along with well-built controls which are user friendly.
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <h1 className="home-text py-2">
            Consolidate and Simplify Voice and Data with
            <span class="span-color"> SIP Trunking </span>
          </h1>
          <p>
            Voxofy Networks LLC brings all the traits of SIP Trunking along with
            well-built controls which are user friendly. Our service will add
            splendid features to the voice use which will anyhow be accustomed
            with the communication solution. Along with maintaining the quality
            of the overall communication setup. It also aids in providing more
            accurate call flow as well routing of the calls. By providing it on
            the cloud of Voxofy Networks LLC, you can employ these attributes
            across the globe.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={tfn3}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
              SIP Trunk – One can opt from
              </div>

              <div className="mt-4">
              <p className="f-18">
              Unlimited Usage- Voxofy Networks LLC is apt to meet your requirements so you can use the SIP Trunks without any uses limits
                <br/>
              <br/>
 Usage Log and Monitoring – gives immediate and sharp usage details of SIP Trunking as Call volume, Bandwidth usage and Concurrent calls and so forth

 <br/>
              <br/>
 Simplistic Management – the dashboard available on the web is user-friendly as it permits to manage easily all SIP Trunk configurations and settings.
              </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container-sm mt-5">
          <div className="row ">
            <div className="col-lg-6 mt-5 ">
              <div class="text-white text-uppercase btn-warning  pt-1 dip">
              Voxofy Networks LLC renders
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">Hosted PBX </span> set up you may be
                familiar
              </h1>
              <div className="mt-4">
                <ul className="p-0">
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp; Feasible plans to meet the need of the hour </li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp; Web based Dashboard for user-friendly control</li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp;  Free set up..</li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp; Maintenance aid.</li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp;Different modes of communication and Media sharing trait also available.</li>
                  <li><img src={Pict} alt="check" className="width-20 pl-2"></img> &nbsp; Disaster Recovery with use of attribute as Call Replication.</li>

                </ul>
              </div>
            </div>
            <div className="col-lg-6 ">
              <img
                src={tfn2}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default SIPtrunk;
