import React from "react";
import { Outbond_Call, tfn3 } from "../../assets";
import { Helmet } from "react-helmet";
const CustomizedMessages = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Customized message: Greet your customers with customized messages.
        </title>
        <meta
          name="description"
          content="Customized message: Greet your customers with customized
messages."
        />
      </Helmet>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Customized<span class="span-color"> Messages</span>
            </h1>
            <p class="hone-p">
              Customized Messages can be sent to the customer on hold or even
              when all the agents are busy taking other calls.
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <p className="f-18">
            Voxofy Networks LLC does not want to be just normal, we firmly
            believe in delivering the outstanding services. Customized Messages
            just adds to attain the same, they are the recorded messages which
            will be played once the customer is on hold or even when all the
            agents are buzzy taking other calls. The message can be a human
            tone, music or a brand tone and so forth. Customized message will
            always act as a boon to the business As we all know that placing the
            caller on hold cannot be a good idea hence we also have attributes
            as Advanced ACD or Simultaneous Ringing feature which indeed helps
            the call to be connected as quick as possible however there is a
            strong possibility because of the high call volume one has to be on
            hold as it becomes the need of the hour, you can help yourself by
            playing Customized Messages and also by appreciating the patience so
            that it gives an impact to the caller that their call and their time
            is valuable.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img src={tfn3} className="img-fluid" alt="inbond call"></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                Customized Messages
              </div>
              <h1 className="fw-bold servicetex mt-1">
                <span class="span-colorr">What is </span> the importance of
                Customized Messages
              </h1>
              <div className="mt-3">
                <p className="f-18">
                  When all the representatives are buzzy taking calls the caller
                  on the other ends hears the beep sound which might be very
                  irritating, it is very important for any business to find out
                  the ways in order to minimize the hold time as we all stand by
                  it that time is money and the customers time should be used
                  very efficiently, by playing promotional messages, providing
                  information about the product, making them aware about the
                  changes and so forth one can keep them occupied Messages can
                  be simply laid down all you will be required to do is choose
                  the voice message that you want to play on the call and then
                  select the extension as well the department for which it is
                  meant to be, on can use this option if need be of setting up
                  the different messages for different extensions in the same
                  department. It gives you complete privilege to make changes as
                  per the need Voxofy Networks LLC has a team of professional to
                  guide you through all the way whether in setting up or
                  Customizing the message
                </p>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default CustomizedMessages;
