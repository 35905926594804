import React from "react";
import {Helmet} from "react-helmet";
import { Outbond_Call, img33 } from "../../assets";

const Callblast = () => {
  return (
    <div>
        <Helmet>
        <title>Call Blast: Expand your business quickly with call blasting</title>
        <meta name="description" content="Helmet application" />
    </Helmet>
      <div className="containe text-center bg-themebg pt-5">
        <div className="container">
          <div className="section-padding">
            <h1 class="home-text py-2">
              Call <span class="span-color"> blasting</span>
            </h1>
            <p class="hone-p">
            Call blast is the most impactful technology and is the apt feature of Voxofy Networks LLC which aids you to join numerous
            </p>
          </div>
        </div>
      </div>

      <div className="container text-center">
        <div className="section-padding">
          <h1 className="home-text py-2">
            What is
            <span class="span-color"> Call Blasting? </span>
          </h1>
          <p>
            Call blast is the most impactful technology and is the apt feature
            of Voxofy Networks LLC which aids you to join numerous customers at
            the same point in time in order to broadcast a taped message. At
            your end you will be only required to mention only the recorded
            message and a lay down the contact numbers to be called and the rest
            will be taken care by us Voxofy Networks LLC.
          </p>
        </div>
      </div>

      {/* new services */}

      <section className="mt-5 pt-3 sec3color">
        <div className="container mt-5">
          <div className="row ">
            <div className="col-lg-6  ">
              <img
                src={img33}
                className="img-fluid"
                alt="inbond call"
              ></img>
            </div>
            <div className="col-lg-6 mt-5 ">
              <div class=" text-white text-uppercase btn-warning  pt-1 dip">
                How it works
              </div>

              <div className="mt-4">
                <p className="f-18">
                  In the initial step you will be required to record a message
                  which you want to be aired and next step will be to mention
                  the numbers in the call blast campaign and last but not the
                  least decide the time for the call, we will call all the
                  numbers at the same point in time and will deliver the
                  message, you at your end can analyze the impact with the help
                  of the dashboard that how many people received the message in
                  human, how many went on voice mail and how many were not
                  delivered and the same can be done by hitting on the Call
                  Result Panel This feature comes in action when you have to
                  give some update, change in the business nature, emergency
                  situation, for surveys, notification, cue and so forth then
                  same mode can be used to impart information to the workmate,
                  customers and clients at the same time. This attribute can
                  also be brought in use for stakeholders as well as the
                  information regarding the change in price can be imparted
                  rather than calling each one out separately, by bringing in
                  use the advanced features will help you to leave greater
                  impact on the customers
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>


    </div>
  );
};
export default Callblast;
